.top_wrap {
    border-bottom: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    padding: 5px 0px !important;
}

.crm_top_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    margin-top: 5px;
}

.search_wrap {
    position: relative;
    margin-left: 20px;
}

.search_wrap svg {
    position: absolute;
    top: 8px;
    left: 8px;
    opacity: 0.4;
    font-size: 20px;
    color: var(--black);
}

.search_wrap input {
    border: unset;
    outline: unset;
    padding: 10px 10px 10px 31px;
    border-radius: 4px;
    width: 200px;
    font-size: 14px;
    font-weight: 500;
    color: var(--black);
    transition: all 300ms;
}

.profile_image {
    width: 2.40625rem;
    border-radius: 50%;
    border: 1px solid #ddd;
}

.profile_text {
    color: var(--primary);
    font-weight: 600;
    font-size: 18px;
}

.profile_name {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 1px solid var(--border-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.expand {
    border-radius: 0px 15px 15px 0px;
    width: 30px;
    height: 30px;
    padding: 8.51px 8.49px 8.49px 8.51px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px 0px #C8C8C8;
    background-color: rgba(255, 255, 255, 0.00)
}

.expand_close {
    border-radius: 0% 50% 50% 0%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px 0 #c8c8c8;
}

.expand_icon {
    position: absolute;
    width: 13px;
    height: 13px;
    flex-shrink: 0;
    background-color: lightgray 0px 0px / 100% 100% no-repeat;
}

.expand_close_icon {
    position: absolute;
    width: 13px;
    height: 13px;
}

.first_name {
    color: var(--table-font-color);
    font-size: 14px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    font-family: var(--primary-font-family) !important;
}

.email_text {
    color: var(--table-header-font);
    font-size: 14px !important;
    margin-top: 3px;
}

.email_text_len {
    color: var(--table-header-font);
    font-size: 14px !important;
    margin-top: 3px;
    width: 165px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.divider {
    height: 0;
    margin: .5rem 0;
    overflow: hidden;
    width: 90%;
    margin-left: 5%;
    border-top: 1px solid rgba(231, 234, 243, .7);
}

.dropdown_text {
    font-size: 14px !important;
    font-weight: 300 !important;
    font-family: var(--primary-font-family) !important;
}

.account_text {
    display: block;
    font-family: var(--primary-font-family) !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    color: var(--table-font-color) !important;
    cursor: pointer !important;
}

.current_plan_text {
    display: block;
    font-family: var(--primary-font-family) !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    color: var(--table-font-color) !important;
}

.manage {
    color: var(--table-header-font);
    font-weight: 400;
    font-size: 13px;
}

.new_tab_icon {
    width: 16px;
    height: 16px;
    margin-left: 3px;
}

.plan_name_text {
    font-size: 10.5px;
    color: #fff;
    background-color: var(--primary);
    border-radius: 50rem;
    padding: 3px 8px;
    margin-left: 5px;
}

.org_name {
    padding: 0 0.9rem;
    font-size: 14px;
    text-transform: capitalize;
    color: var(--table-font-color);
    margin-top: 2px;
}

.org_name_text {
    width: 180px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 0.9rem;
    font-size: 14px;
    text-transform: capitalize;
    color: var(--table-font-color);
    margin-top: 2px;
}

.current_plan_container {
    display: flex;
    align-items: center;
}

.org_wrap {
    width: 400px;
    position: absolute;
    translate: -50% -50%;
    top: 50%;
    left: 50%;
    background-color: var(--white);
    border-radius: 10px;
    padding: 10px;
}

.org_container {
    padding: 30px !important;
}

.org_textfield {
    display: flex !important;
    justify-content: space-between !important;
    font-family: var(--primary-font-family);
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    text-align: left;
    color: var(--table-font-color);
}

.org_close {
    position: absolute !important;
    top: 8px;
    right: 8px;
}

.pagination_item {
    display: flex !important;
    justify-content: center !important;
    font-family: var(--primary-font-family) !important;
}

.switch_btn {
    text-transform: capitalize !important;
    width: 100% !important;
    margin-top: 20px !important;
    padding: 14px 25px !important;
}

.plan_name {
    color: var(--table-font-color);
    font-family: var(--primary-font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    white-space: nowrap;
}

.get_centilio {
    font-size: 18px;
    font-family: var(--primary-font-family);
    font-weight: 700 !important;
    background: -webkit-linear-gradient(45deg, #377DFF, #171A1F);
    background-clip: text;
    cursor: pointer;
    -webkit-text-fill-color: transparent;
}

.get_centilio:hover {
    text-shadow: #4646ff47 0 0 3px;
}

.plan_wrapper {
    margin-right: 20px;
    font-family: var(--primary-font-family);
}

.days_left {
    font-size: 9px !important;
    font-weight: 500 !important;
    color: var(--danger) !important;
    margin-top: 2px !important;
    float: right;
}

.left_container {
    display: flex;
    align-items: center;
    margin-right: 24px;
}

.star_image {
    width: 14px;
    height: 14px;
}

.star_small_image {
    width: 10px;
    height: 10px;
    margin: 0px 5px 10px 0px !important;
}

.days_container {
    padding: 0 10px;
}

.days_left_image {
    width: 30px;
    height: 30px;
}

.info_wrap {
    display: flex;
    padding: .5rem .9rem;
}