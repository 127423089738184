.file_wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 620px;
    background-color: #fff;
    /* border: 1px solid var(--border-color); */
    border-radius: 6px;
    box-shadow: 24;
    /* padding: 40px;  */
}

.file_head_name {
    background-color: #3899ec;
    min-height: 60px;
    padding: 18px;
    padding-left: 30px;
    font-weight: 400;
    font-size: 20px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    border-radius: 8px 8px 0 0;
    line-height: 24px;
    font-weight: bold;
}

.schedule_date_time {
    padding: 36px 30px 12px;
}

.time_zone_wrap {
    display: flex;
    align-items: center;
}

.time_zone {
    font-size: 16px;
}

.info {
    color: var(--primary);
    border: 1.5px solid var(--primary);
    border-radius: 50%;
    font-size: 12px;
    font-weight: 600;
    width: 17px;
    height: 17px;
    margin: 2px 0px 0px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}