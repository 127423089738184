.nav_wrap {
    min-width: 250px;
    width: 250px;
    border-right: 1px solid rgba(231, 234, 243, 0.70);
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    /* overflow: hidden !important; */
    height: 100%;
    /* gap: 25px; */
    transition: width 250ms ease-in-out, min-width 250ms ease-in-out;
}

.nav_expand {
    width: 60px;
    min-width: 60px;
    display: flex;
    /* gap: 40px; */
}

.nav_item {
    cursor: pointer;
    padding-left: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 3px;
    text-decoration: unset;
    color: var(--secondary);
    padding: 1%;
}

.nav_item_active {
    color: var(--secondary);
    background-color: #f1efff;
    padding: 1%;
    border-radius: 7px;
    cursor: pointer;
    padding-left: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
    text-decoration: unset;
}

.nav_item:hover {
    cursor: pointer;
    padding-left: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 7px;
    text-decoration: unset;
    color: var(--secondary);
    background-color: rgba(189, 197, 209, .2);
    padding: 1%;
    color: var(--sidebar-icon-color) !important;
}

.nav_item:hover img {
    filter: invert(60%) sepia(95%) saturate(4766%) hue-rotate(205deg) brightness(98%) contrast(106%);
}

.nav_item p {
    margin: 11px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-family: var(--primary-font-family) !important;
    font-style: normal !important;
}

.active_nav {
    color: var(--secondary);
    background-color: #f1efff;
    padding: 1%;
    border-radius: 7px;
}

.welcome_img {
    width: 30px;
    height: 30px;
}

.nav_container {
    display: flex;
    gap: 5px;
    padding: 20px 0px 5px 0px;
    cursor: pointer;
    text-decoration: unset;
    color: inherit;
    align-items: center;
}

.nav_active_text {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 21px;
    translate: -9px;
}

.centilio_sign {
    font-weight: 600 !important;
    font-size: 20px !important;
    margin-left: 6px !important;
    font-style: normal !important;
    font-family: var(--primary-font-family) !important;
    color: var(--header-color) !important;
    line-height: 30px !important;
    letter-spacing: 0.188px !important;
}

.centilio_title {
    display: flex !important;
    gap: 11px !important;
    padding: 18px 0px 0px 10px !important;
    cursor: pointer !important;
    text-decoration: unset !important;
    color: inherit !important;
    align-items: center !important;
}

.nav_name {
    width: 26px !important;
    height: 26px !important;
    margin-left: 15px;
}

.skeleton_wrapper {
    cursor: pointer;
    padding-left: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 3px;
    text-decoration: unset;
    color: var(--secondary);
    padding: 1%;
}

.nav_body {
    margin-top: 20px;
}