.tracking_code_head {
    text-align: center;
    font-family: var(--primary-font-family);
    color: var(--table-font-color);
}

.tracking_code_list {
    /* text-align: center; */
    font-family: var(--primary-font-family);
    font-size: 12px;
    color: var(--table-font-color);
}

.tracking_code_textarea {
    width: 100%;
    text-align: center;
}

.tracking_code_button_div {
    width: 100%;
    text-align: end;
    padding: 2px 20px 20px 20px;
}

.tracking_code_button {
    text-transform: none;
}