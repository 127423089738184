.delete_icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.delete_icon:hover {
    color: red;
}

.table_filter_head {
    color: var(--header-color);
    font-family: var(--primary-font-family);
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
    margin: 0;
    border-bottom: 1px solid rgba(231, 234, 243, 0.70);
    padding-bottom: 10px;
}

.table_filter_delete_search {
    display: flex;
    gap: 10px;
}