.authorize_heading {
    text-align: center;
    padding: 10px;
    font-family: var(--primary-font-family);
}

.authorize_phara {
    text-align: center;
    padding: 10px 115px;
    font-family: var(--primary-font-family);
    font-size: 12px;
}

.authorize_button_div {
    width: 100%;
    text-align: center;
    padding: 20px 0px 10px 0px;
}

.authorize_button {
    width: 50%;
    text-transform: none !important;
    font-family: var(--primary-font-family);
}

.authorize_note {
    text-align: center;
    padding: 0px 75px;
    font-family: var(--primary-font-family);
    font-size: 10px;
}


.authorize_previous_button_div {
    width: 100%;
    text-align: end;
    padding: 20px;
}
.authorize_previous_button {
  text-transform: none !important;
}