.table_border_top {
    border: 1px solid rgba(231, 234, 243, 0.70);
    border-radius: 20px;
    background-color: var(--white);
}


.table_border {
    border-bottom: 1px solid rgba(231, 234, 243, 0.70);
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding-bottom: 10px; */
    padding: 10px 35px;
}

.table_filter {
    display: flex;
    gap: 10px;
}

.table_filter_name {
    padding-top: 5px;
}

.table_user_details {
    width: 50%;
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.table_user_title {
    background-color: var(--white);
    border-left: 5px solid var(--white);
    border-bottom: 1px solid #eef0f6;

}

.table_user_left_border {
    border-left: 5px solid var(--primary) !important;
}

.table_user_title:hover {
    background-color: #f4f7ff;
    border-left: 5px solid #f4f7ff;
}

.table_user_title::before {
    background-color: var(--primary);
}

.table_user_title_border {
    display: flex;
    justify-content: space-between;
    padding: 15px 30px;
}



.modal_popup {
    display: flex;
    padding: 5px;
    /* gap: 20px; */
    padding-top: 10px;
}

.modal_popup_title {
    padding-top: 5px;
    width: 200px;
    /* text-align: center; */
}

.modal_popup_input {
    height: 35;
}

.table_btn_div .table_btn_edit {
    display: none;
    font-family: var(--primary-font-family) !important;
    text-transform: none !important;
    border-radius: 20px !important;
    height: 30px !important;

}

.modal_popup_draft_btn {
    display: flex;
    padding: 20px;
    gap: 10px;
    justify-content: flex-end;
}

.modal_popup_draft_btn button {
    text-transform: none !important;
    font-size: 14px;
    font-family: var(--primary-font-family);
}

.table_user_title:hover .table_btn_edit {
    display: inline-flex;
}

.table_user_more_btn {
    border: 1px solid rgba(231, 234, 243, 0.70);
    border-radius: 50%;
    fill: var(--primary);
}

.table_user_more_btn:hover {
    /* border: 1px solid rgba(231, 234, 243, 0.70);
    border-radius: 50%; */
    background-color: var(--primary);
    fill: var(--white);
}

.table_user_delete_btn {
    border: 1px solid rgba(231, 234, 243, 0.70);
    border-radius: 50%;
    fill: var(--danger) !important;
    cursor: pointer !important;
}

.table_user_delete_btn:hover {
    background-color: rgb(255 55 55 / 8%) !important;
    fill: var(--danger) !important;
    cursor: pointer !important;
}

.table_btn_div {
    display: flex;
    gap: 20px;
    padding-top: 5px;
    justify-content: start;
    align-items: center;
}

.table_edit_popover {
    position: relative;
    /* mt: 10px; */

}

.table_edit_popove::before {
    background-color: var(--white);
    content: '';
    display: block;
    position: absolute;
    width: 12;
    height: 12;
    top: -6;
    transform: rotate(45deg);
    left: calc(50% - 6px);
}

.table_moreinfo_popper {
    /* border: 1px solid rgba(231, 234, 243, 0.70); */
    display: flex;
    flex-direction: column;
    /* border-radius: 10px; */
    /* box-shadow: var(--box-shadow); */
    padding: 10px;
}

.table_moreinfo_popper button {
    justify-content: start !important;
    text-transform: none !important;
    color: black;
}

.table_body_data {
    height: 65vh;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.table_trash_btns {
    display: flex;
    gap: 20px;
}

.table_trash_btns button {
    text-transform: none !important;
    font-size: 14px;
    font-family: var(--primary-font-family);
    border-radius: 10px !important;
}

.table_trash_btns .table_trash_btns_delete {
    color: var(--danger) !important;
}

.table_moreinfo_popper .table_trash_btns_delete {
    color: var(--danger) !important;
}

.table_trash_btns .table_trash_btns_delete:hover {
    background-color: rgb(255 55 55 / 8%) !important;
}

.table_moreinfo_popper .table_trash_btns_delete:hover {
    background-color: rgb(255 55 55 / 8%) !important;
}

.table_Select_box span {
    font-size: 14px;
    font-family: var(--primary-font-family);
}

.table_moreinfo_popper button {
    font-size: 14px;
    font-family: var(--primary-font-family);
}

.table_title_name {
    font-size: 14px;
    font-family: var(--primary-font-family);
    font-weight: 500;
    width: 450px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.table_title_phara {
    font-family: var(--primary-font-family);
    font-weight: 400;
    font-size: 12px;
    padding-top: 0.5rem;
}

.table_body_error_head {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
}

.table_body_error_text {
    font-family: var(--primary-font-family);
    color: var(--black);
}

.table_body_error {
    display: flex;
    justify-content: center;
}

.date_picker_wrap {
    width: 100%;
    display: flex;
}

.date_picker {
    width: 100% !important;
    height: 41.92px !important;
    border: 1px solid var(--border-color) !important;
    border-radius: 4px !important;
    outline: unset;
    padding-left: 10px;
    font-size: 14px !important;
    font-family: var(--primary-font-family);
    position: relative;
    width: 327px;
}

.date_picker:hover {
    border-color: var(--primary) !important;
}

.date_pickerr {
    width: 100%;
}

.clear_icon_wrap {
    position: absolute;
    right: 0px !important;
    margin: 10px !important;
}

.clear_icon_wrap svg {
    color: rgba(0, 0, 0, 0.54) !important;
}

.link_edit {
    color: white;
    text-decoration: unset;
}

.table_title_name span {
    /* width: 650px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; */
}

.tooltip_table span {
    width: 350px;
    color: red;
    text-overflow: ellipsis;
    max-width: 350px;
    white-space: nowrap;
    overflow: hidden;
}


@media (max-width: 600px) {
    .table_body_data {
        height: 45vh;
        /* Adjust height for smaller screens */
    }
}

@media (max-height: 700px) {
    .table_body_data {
        height: 55vh;
    }
}

.file_wrap {
    background-color: var(--white);
    border-radius: 10px;
    padding: 30px;
    height: 100%;
    transition: 250ms ease-in-out;
}

.title_wrap {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.title_text {
    font-size: 16px;
    color: var(--table-font-color);
    padding: 0px 10px 20px 0px;
    font-weight: 600;
}

.tags_btn {
    width: 130px !important;
    height: 42.59px !important;
    font-size: 14px !important;
    margin-right: 15px !important;
}

.tags_menu_item {
    display: flex;
    font-family: var(--primary-font-family) !important;
    font-size: 14px !important;
    color: var(--header-color) !important;
    align-items: center !important;
    margin: 0px 5px !important;
    min-height: 10px !important;
}

.socialImg_icon_wrap {
    display: flex;
    gap: 10px;
}

.socialImg_icons {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: antiquewhite;
    position: relative;
    cursor: pointer;
}

.img_icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.social_page_img {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    margin-right: 5px;
}

.icon_wrap {
    position: absolute;
    bottom: -4px;
    right: -3px;
}

.social_icon_img {
    width: 18px;
    height: 18px;
}

.selected_icon_img {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    vertical-align: unset;
    margin-top: 20px;
}

.text_field {
    width: 100% !important;
    height: 500px !important;
    border: 1px solid #E6E7E9;
    border-radius: 4px;
    margin-top: 10px;
}

.drop_img {
    border: 2px dotted #E6E7E9;
    width: 100px;
    height: 100px;
    text-align: center;
    padding-top: 10px;
    line-height: 17px;
    border-radius: 4px;
}

.post_images {
    width: 100px;
    height: 100px;
    border-radius: 4px;
}

.post_img_container {
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 17px;
    position: relative;
    display: inline-block;
}

.post_upload_wrap {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.upload_stickers {
    display: flex;
    border-top: 1px solid #E6E7E9;
    height: 46px;
    align-items: center;
    padding: 10px;
    position: relative;
}

.drop_img_text {
    font-size: 12px;
    color: var(--table-font-color);
}

.drop_img_select_text {
    font-size: 12px;
    color: var(--primary);
}

.emoji_icon_wrap {
    cursor: pointer;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.emoji_icon_wrap:hover {
    background-color: rgb(245, 245, 245);
    border-radius: 4px;
}

.emoji_popup {
    position: absolute;
    top: 50px;
    left: 0;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 10px;
    max-width: 300px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30px, 1fr));
    gap: 5px;
    z-index: 100;
}

.emoji_option {
    font-size: 24px;
    cursor: pointer;
    transition: transform 0.2s;
}

.emoji_option:hover {
    transform: scale(1.2);
}

.divider {
    border: 1px solid rgb(224, 224, 224);
    height: 18px;
    margin: 0px 5px;
}

.post_btn {
    font-size: 14px !important;
    height: 41.92px !important;
    text-transform: capitalize !important;
    float: right !important;
    margin-top: 25px !important;
}

.social_page_name {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: rgb(61, 61, 61);
    align-items: center;
    display: flex;
    gap: 5px;
    padding: 10px 0px 10px 0px;
}

.user_name {
    color: var(--header-color);
    text-overflow: ellipsis;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
}

.user_sub_name {
    font-size: 12px;
    font-weight: 400;
    color: var(--table-header-font);
}

.common_wrap {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 10px;
}

.common_title_text {
    display: flex;
    align-items: center;
    gap: 10px;
    line-height: 15px;
}

.social_post_page_img {
    display: flex;
    overflow: hidden;
    align-items: stretch;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 3px;
    border-radius: 12px;
    margin-top: 10px;
}

.social_post_text {
    font-size: 14px;
    color: var(--table-font-color);
    line-height: 20px;
    margin-top: 5px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
}

.facebook_preview {
    border: 1px solid var(--border-color);
}

.earth_planet_img {
    width: 10px;
    height: 10px;
}

.bottom_icon_img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.three_dot_img {
    width: 15px;
    height: 15px;
    right: 0;
}

.facebook_post_page_img {
    display: flex;
    overflow: hidden;
    align-items: stretch;
    flex-direction: column;
    justify-content: space-between;
    gap: 3px;
    margin-top: 10px;
}

.img_container {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
}

.facebook_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 12px;
    font-size: 14px;
    color: var(--table-font-color);
}

.facebook_bottm_container {
    align-items: center;
    display: flex;
}

.insta_bottom_text {
    font-size: 14px;
    color: var(--header-color);
    font-weight: 600;
    padding: 0px 10px 10px 10px;
}

.insta_bottom_text_val {
    color: var(--table-font-color) !important;
    font-weight: 400 !important;
    margin-left: 5px;
    display: table-row;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
}

.profile_text {
    color: var(--primary);
    font-weight: 600;
    font-size: 18px;
}

.remove_icon {
    position: absolute;
    top: -4px;
    right: -2px;
    width: 18px;
    height: 18px;
    cursor: pointer;
}