.home_head {
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
}

.home_page_name {
    font-size: 22px;
    font-weight: 600;
    margin: 0;
    font-family: var(--primary-font-family);
    line-height: 28px;
    color: var(--header-color);
    padding: 5px 10px;
}

.home_head .home_button {
    text-transform: none !important;
    border-radius: 20px !important;
}

.home_page_btn_div {
    display: flex;
    gap: 30px;
}

.home_btn {
    padding-top: 5px;
}

.home_btn button {
    font-size: 14px;
    font-family: var(--primary-font-family);
    text-transform: none !important;
}

.label_name button {
    font-size: 14px;
    font-family: var(--primary-font-family);
    text-transform: none !important;
}

.label_name {
    padding: 0rem 2rem;
    border-bottom: 1px solid rgba(231, 234, 243, 0.70);
}

.file_wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    background-color: #fff;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    box-shadow: 24;
    padding: 40px;
}

.close_btn {
    position: absolute !important;
    top: 10px;
    right: 10px;
    margin-left: 5px;
    z-index: 1 !important;
}

.popup_head {
    position: absolute !important;
    top: 20px;
    z-index: 1 !important;
    font-family: var(--primary-font-family) !important;
    color: var(--table-header-font);
    font-weight: bold;
    font-size: large;
}

.popup_paragraph {
    font-family: var(--primary-font-family) !important;
    color: var(--table-header-font);
    padding-top: 15px;
}

.popup_btn {
    padding-top: 20px;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}

.file_post_container {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    width: 100%;
}

.post_label {
    display: flex;
    justify-content: start !important;
    width: 25%;
    align-items: center;
    margin-bottom: 10px;
    color: var(--table-font-color) !important;
    font-family: var(--primary-font-family) !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    text-overflow: unset;
}

.file_post_input {
    width: 75% !important;
}

.file_post_inputs {
    width: 100% !important;
}

.file_post_input.css-1fdw02k-MuiStack-root>.MuiTextField-root {
    min-width: 500px !important;
}

.btn_container {
    bottom: 0px !important;
    width: 100%;
    margin-top: 30px;
}

.review_btn {
    text-transform: capitalize !important;
    float: right;
    height: 41.92px !important;
    text-wrap: nowrap !important;
}

.review_draft_btn {
    text-transform: capitalize !important;
    float: right;
    height: 41.92px !important;
    text-wrap: nowrap !important;
    color: var(--table-header-font);
}

.review_draft_btn:hover {
    color: var(--primary);
}

.post_file_wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    margin-top: 30px;
}

.css-aza09e-MuiStack-root {
    overflow: hidden !important;
}

.rmdp-container {
    width: 100%;
}

.helper_text_keyword {
    color: #d32f2f;
    font-size: 12px;
    margin: 5px 0px 0px 27%
}

.tag_input_error {
    width: 75%;
}

.tag_input_errors {
    width: 0%;
}

.tag_input_error div {
    width: 100%;
    border: 1px solid #d32f2f;
    font-size: 14px !important;
    border-radius: 4px !important;
    overflow-wrap: anywhere;
}

.tag_input_wrap div {
    width: 100%;
    border: 1px solid #E6E7E9;
    font-size: 14px !important;
    border-radius: 4px !important;
    overflow-wrap: anywhere;
}

.tag_input_wrap {
    width: 75%;
}

.tag_input_wraps {
    width: 0%;
}

.tag_input_wrap div:hover {
    border-color: var(--primary);
}

.tag_input_wrap div:focus-within {
    box-shadow: none !important;
    border-color: none !important;
}

.tag_input_wrap div span span {
    font-size: 14px !important;
}

.tag_input_wrap input::placeholder {
    color: #a7a8ab6e;
}

.upload_body {
    display: flex;
    align-items: center;
}

.upload_body svg {
    color: #fff;
}

.upload_button {
    color: #fff !important;
    height: 42.59px !important;
    font-size: 14px !important;
    text-transform: capitalize !important;
    border-radius: 6px 0px 0px 6px !important;
    box-shadow: none !important;
}

.upload_document {
    background-color: var(--primary) !important;
    color: #fff !important;
    height: 42.59px !important;
    font-size: 14px !important;
    border-radius: 0px 6px 6px 0px !important;
}

.upload_document:hover {
    background-color: rgb(38, 87, 178) !important;
}

.file_upload_wrapper {
    font-size: 13px !important;
    font-weight: 400 !important;
    width: 90% !important;
    border-radius: 6px !important;
    display: flex !important;
    margin-left: 9px !important;
    background-color: #fff !important;
}

.file_upload_wrapper:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
}

.file_upload_svg {
    width: 17px;
    height: 17px;
    margin-right: 7px;
}

.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    color: none !important;
}

.date_picker_wrap {
    width: 75%;
    display: flex;
    position: relative;
}

.date_picker {
    width: 100% !important;
    height: 41.92px !important;
    border: 1px solid var(--border-color) !important;
    border-radius: 4px !important;
    outline: unset;
    padding-left: 10px;
    font-size: 14px !important;
    font-family: var(--primary-font-family);
    position: relative;
}

.date_picker:hover {
    border-color: var(--primary) !important;
}

.date_pickerr {
    width: 100%;
}

.label_name_swipe div {
    transition: none !important;

}

.btn_group {
    gap: 10px;
    display: flex;
    justify-content: flex-end;
}

.clear_icon_wrap {
    position: absolute;
    right: 0px !important;
    margin: 10px !important;
}

.clear_icon_wrap svg {
    color: rgba(0, 0, 0, 0.54) !important;
}

.input_suggestion {
    display: flex;
    align-items: center;
    padding-left: 1rem;
    height: 35px;
}

.tag_selection {
    display: flex;
    justify-content: space-between;
    color: white;
    font-size: 14px;
    font-family: var(--primary-font-family);
    padding: 0px 10px 0px 10px;
    height: 15px;
}

.tag_selection_new {
    padding-top: 5px;
    font-size: 10px;
}

.tag_text {
    font-size: 10px;
    width: 40%;
    padding-top: 3px;
    cursor: pointer;
    text-align: end;
}

.tag_value {
    width: 60%;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: start;
}

.paperStyle {
    background-color: #41b883;
}

.outline_input input {
    padding: 10px 15px;
}

.info {
    color: var(--primary);
    border: 1.5px solid var(--primary);
    border-radius: 50%;
    font-size: 11px;
    font-weight: 600;
    width: 15px;
    height: 15px;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}