.view .jodit-react-container {
    height: calc(100vh - 190px) !important;
  }
  
  .view .jodit-wysiwyg_mode {
    height: calc(100vh - 190px) !important;
  }
  
  .view .jodit-workplace {
    height: calc(100vh - 190px) !important;
  }
  
  .view .jodit-wysiwyg {
    height: 100% !important;
    -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  }
  
  .view .jodit-toolbar__box {
    /* --toolbar__box_margin_bottom:0px;   */
    /* margin-bottom: 32px; */
    margin-bottom: var(--toolbar__box_margin_bottom,56px)
  }
  
  .custom_textarea {
    resize: none;
    outline: unset;
    border: unset;
    position: absolute;
    top: var(--custom_textarea_margin_top);
    width: calc(100% - 120px);
    min-height: 50px;
    height: auto;
    font-size: 30px;
    font-weight: bold;
    z-index: 1;
  }
  
  .view .jodit-container:not(.jodit_inline) {
    background-color: #ffffff;
    border: 1px solid #ffffff !important;
    border-radius: 3px;
  }
  .view .jodit-wysiwyg p {
    margin: 3px 0 0 0 !important;
  }