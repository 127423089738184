.integration_body {
    background: #ffffff;
    border: 1px solid rgba(231, 234, 243, 0.7) !important;
    box-shadow: 0 0.375rem 0.75rem #8c98a414 !important;
    /* width: 29% !important; */
    max-width: 500px;
    min-width: 400px;
    display: inline-block;
    border-radius: 6px;
    transition: 0.3s;
    cursor: default !important;
    position: relative;
    margin: 2%;
}

.integration_body:hover {
    box-shadow: var(--box-shadow) !important;
    cursor: default !important;
}

.integration_box {
    display: flex;
    height: 40px;
    justify-content: space-between;
    align-items: center;
}

.integration_box_connect {
    display: flex;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    opacity: 0.2;
}

.dropbox_img {
    width: 40px;
    height: 40px;
}

.box_connect {
    border: 1px solid var(--border-color) !important;
    text-transform: capitalize !important;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    background-color: #ffffff;
    box-shadow: 0 0.375rem 0.75rem #8c98a414 !important;
    color: var(--table-font-color) !important;
}

.box_update {
    font-size: 10px !important;
    font-weight: 400 !important;
    color: var(--primary) !important;
    margin: 10px !important;
    cursor: pointer !important;
}

.box_connect:hover {
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: var(--box-shadow) !important;
}

.integration_text_header {
    font-size: 14px;
    font-weight: 500px;
}

.integration_text {
    margin-top: 20px;
}

.integration_text_connect {
    margin-top: 20px;
    opacity: 0.2;
}

.top_text {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: var(--table-font-color) !important;
    font-family: var(--primary-font-family) !important;
}

.bottom_text {
    font-size: 14px !important;
    font-weight: 400 !important;
    margin-top: 5px !important;
    color: var(--table-header-font) !important;
    font-family: var(--primary-font-family) !important;
}

.modal_wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    background-color: #fff;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    box-shadow: 24;
    padding: 40px;
    z-index: 9999 !important;
}

.close_btn {
    position: absolute !important;
    top: 10px;
    right: 10px;
    margin-left: 5px;
    z-index: 1 !important;
}

.main_wrap {
    width: 100%;
}

.file_post_container {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;
}

.post_label {
    width: 25%;
    display: flex;
    align-items: center;
    color: var(--table-font-color) !important;
    font-family: var(--primary-font-family) !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    text-overflow: unset;
}

.file_post_input {
    width: 75%;
}

.btn_container {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 30px;
}

.review_btn {
    text-transform: capitalize !important;
    height: 42.59px !important;
    margin-top: 50px;
}

.modal *:focus {
    outline: none !important;
}

.box_connected {
    border: none !important;
    text-transform: capitalize !important;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    background-color: #23ab94 !important;
    box-shadow: 0 0.375rem 0.75rem #8c98a414 !important;
    color: #fff !important;
}

.disconnect {
    position: absolute;
    display: flex;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    opacity: 0 !important;
}

.disconnect_button {
    text-transform: capitalize !important;
}

.reconnect_button {
    text-transform: capitalize !important;
    border: 1px solid #23ab94 !important;
    color: #23ab94 !important;
}

.settings_img {
    position: absolute;
    right: 15px;
    top: 15px;
}

.nav_name {
    width: 25px !important;
    height: 25px !important;
    cursor: pointer !important;
}

.integration_container {
    width: 100%;
    height: 100%;
    align-items: center;
    overflow: auto;
    height: calc(100vh - 72px);
}

.integration_container::-webkit-scrollbar-track {
    background-color: inherit;
}

.integration_container::-webkit-scrollbar {
    max-height: 12px;
    max-width: 5px;
    min-height: 8px;
    min-width: 5px;
    background-color: #ffffff;
}

.integration_container::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px #0000004d;
    background: #b8b8b8;
    border-radius: 5px;
    width: 5px;
    height: 5px;
}

.file_wrap {
    width: 500px;
    position: absolute;
    translate: -50% -50%;
    top: 50%;
    left: 50%;
    background-color: var(--white);
    border-radius: 10px;
    padding: 25px;
}

.confirm_text {
    font-size: 14px;
    color: var(--table-header-font);
    padding: 5px 10px 20px 10px;
}

.facebook_page_letter {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid var(--border-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.facebook_page_wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.facebook_page_text {
    color: var(--table-font-color);
    font-weight: 600;
    font-size: 14px;
}

.facebook_page_name {
    font-size: 14px;
    color: var(--table-font-color);
    line-height: 40px;
}

.delete_modal_buttons {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.delete_container_cancel {
    height: 41.92px !important;
    font-size: 14px !important;
    border: 1px solid var(--border-color) !important;
    text-transform: capitalize !important;
    color: var(--table-header-font) !important;
}

.delete_container_cancel:hover {
    color: var(--primary) !important;
}

.delete_modal {
    font-size: 14px !important;
    height: 41.92px !important;
    text-transform: capitalize !important;
}