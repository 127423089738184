.top_bar {
    height: 75vh;
    overflow-x: scroll;
}

.main_wrap {
    width: 100%;
    /* height: 100%; */
    padding: 20px 30px;
}

.settings_body {
    width: 100%;
    /* height: 100%; */
    border: 1px solid var(--border-color) !important;
    border-radius: 10px;
    box-shadow: 0 .375rem .75rem #8c98a414;
    display: block;
}

.file_post_container {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 30px;
}

.post_label {
    display: flex;
    width: 15%;
    align-items: center;
    margin-bottom: 10px;
    color: var(--table-font-color) !important;
    font-family: var(--primary-font-family) !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    text-overflow: unset;
}

.blog_label {
    display: flex;
    width: 15%;
    align-items: center;
    color: var(--table-font-color) !important;
    font-family: var(--primary-font-family) !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    text-overflow: unset;
}

.file_post_input {
    width: 85% !important;
}

.btn_container {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 30px;
}

.review_btn {
    text-transform: capitalize !important;
    height: 42.59px !important;
    margin-top: 50px;
}

.document-header {
    text-align: left;
    padding: 5px 0;
}

.header_title {
    text-align: left;
    padding: 5px 0;
}

.title {
    display: inline-block;
    margin: 0 !important;
    padding: 20px 0 10px 30px !important;
}

.header_text {
    color: var(--header-color) !important;
    font-weight: 600;
    font-size: 22px;
    font-family: var(--primary-font-family);
    line-height: 28px;
}

.wix_setting {
    border-bottom: 1px solid #cbd0e06e;
    color: var(--header-color) !important;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding: 15px 22px;
}

.time_zone_select {
    width: 100%;
    height: 35px;
    border: 1px solid var(--border-color);
}

.css-z681da-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 0px !important;
}

.css-13qalpc-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0px !important;
}

.wix_list {
    /* height: 68vh;
    overflow-x: scroll; */
}

.top_bar::-webkit-scrollbar {
    display: none;
}

.not_connected_text {
    color: var(--table-font-color);
    font-size: 14px;
    display: flex;
    align-items: center;
}