.main_wrap {
    width: 100%;
    height: 100%;
    position: relative;
}

.header_name {
    font-size: 22px;
    font-weight: 600;
    margin: 0;
    font-family: var(--primary-font-family);
    line-height: 28px;
    color: var(--header-color);
    padding: 15px 0px;
}

.calendar_wrapper {
    overflow-y: scroll;
    height: calc(100vh - 130px);
    padding: 10px;
}

.calendar_wrapper::-webkit-scrollbar-track {
    background-color: inherit;
}

.calendar_wrapper::-webkit-scrollbar {
    max-height: 12px;
    max-width: 5px;
    min-height: 8px;
    min-width: 5px;
    background-color: #ffffff;
}

.calendar_wrapper::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px #0000004d;
    background: #b8b8b8;
    border-radius: 5px;
    width: 5px;
    height: 5px;
}

.today_btn {
    border: 1px solid var(--border-color) !important;
    color: var(--table-header-font) !important;
    text-transform: capitalize !important;
    font-family: var(--primary-font-family) !important;
    margin-left: 10px !important;
}

.today_btn:hover {
    border: 1px solid var(--border-color) !important;
    color: var(--primary) !important;
    background-color: transparent !important;
}

.month_year_btn {
    border: 1px solid var(--border-color) !important;
}

.year_month_btn {
    width: 130px !important;
    height: 42.59px !important;
    font-size: 14px !important;
    margin-right: 15px !important;
}

.header_body {
    border-bottom: 1px solid var(--border-color);
    margin: 0px 15px;
}

.calendar_header {
    color: var(--table-font-color);
    font-size: 18px;
    font-family: var(--primary-font-family);
    font-weight: 600;
    margin-left: 10px;
}

.calendar_title {
    font-size: 13px;
    padding: 8px !important;
    border: none !important;
    white-space: nowrap;
    display: block !important;
    overflow: hidden !important;
    width: 100%;
    height: 100%;
}

.calendar_title:hover {
    color: var(--primary) !important;
}

.day_header_text {
    font-size: 12px;
    padding: 8px;
    text-transform: uppercase;
    color: var(--table-header-font);
}

.day_header_text_list {
    font-size: 14px;
    padding: 8px;
    text-transform: capitalize;
    color: var(--table-font-color);
}

.more_condition_icon_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: relative;
}

.year_month_menu_item {
    display: flex;
    font-family: var(--primary-font-family) !important;
    font-size: 14px !important;
    color: var(--header-color) !important;
    align-items: center !important;
    margin: 0px 5px !important;
    min-height: 10px !important;
}

.remove_border {
    border: none !important;
    box-shadow: none !important;
}

.fc-day-today {
    background-color: transparent !important;
}

.search_field {
    border: 1px solid var(--border-color) !important;
    padding: .6125rem 1rem !important;
}

.card_subtitle {
    display: block;
    text-transform: uppercase;
    font-size: 11px !important;
    font-family: var(--primary-font-family) !important;
    margin-bottom: .25rem;
    width: 110px;
    justify-content: center;
    display: flex;
    align-items: center;
    min-height: 0 !important;
    padding: 4px 16px !important;
    color: var(--table-header-font) !important;
}