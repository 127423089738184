.integrations_head {
    /* display: flex; */
    gap: 30px;
    padding: 20px;
}

.integrations_stepper_top {
    width: 15%;
    position: absolute;
    margin-top: 25px;
}

.integrations_stepper_top_head {
    width: 100%;
}

.integrations_stepper_heading {
    font-family: var(--primary-font-family);
    font-weight: bold;
    text-align: center;
    color: var(--header-color);
}

.integrations_taps_box {
    display: flex;
    justify-content: center;
    padding: 30px;
}

.integrations_trackingcode_box {
    width: 600px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    height: 300px;
    display: flex;
    align-items: center;
}

.integrations_authorize_box {
    width: 600px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    height: 300px;
    display: flex;
    align-items: center;
}