.file_wrap {
    width: 500px;
    position: absolute;
    translate: -50% -50%;
    top: 50%;
    left: 50%;
    background-color: var(--white);
    border-radius: 10px;
    padding: 30px;
}

.delete_modal_text {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    color: var(--table-font-color);
    text-align: center;
}

.delete_modal_buttons {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.delete_container_cancel {
    height: 41.92px !important;
    font-size: 14px !important;
    border: 1px solid var(--border-color) !important;
    text-transform: capitalize !important;
    color: var(--table-header-font) !important;
}

.delete_container_cancel:hover {
    color: var(--primary) !important;
}

.delete_modal {
    font-size: 14px !important;
    height: 41.92px !important;
    text-transform: capitalize !important;
}