.date_picker {
    width: 100% !important;
    height: 41.92px !important;
    border: 1px solid rgb(112 115 125) !important;
    border-radius: 4px !important;
    outline: unset;
    padding-left: 10px;
    font-size: 16px !important;
    font-family: var(--primary-font-family);
    position: relative;
}

.time_zone_wrap {
    display: flex;
}

.time_zone {
    font-family: var(--primary-font-family);
    color: var(--table-header-font);
    font-size: 16px;
}

.info {
    color: var(--primary);
    border: 1.5px solid var(--primary);
    border-radius: 50%;
    font-size: 12px;
    font-weight: 600;
    width: 17px;
    height: 17px;
    margin: 2px 0px 0px 5px;
    text-align: center;
    cursor: pointer;
}