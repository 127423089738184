.file_wrap{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 620px;
    background-color: #fff;
    /* border: 1px solid var(--border-color); */
    border-radius: 6px;
    box-shadow: 24;
    padding: 40px; 
}
.popup_head{
    position: absolute !important;
    top: 20px;
    z-index: 1 !important;
    font-family: var(--primary-font-family) !important;
    color: var(--table-header-font);
    font-weight: bold;
    font-size: large;
}
.close_btn {
    position: absolute !important;
    top: 10px;
    right: 10px;
    margin-left: 5px;
    z-index: 1 !important;
}
.popup_paragraph{
    font-family: var(--primary-font-family) !important;
    color: var(--table-header-font);
    padding-top: 15px;
}
.popup_btn{
    padding-top: 20px;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}