.label_name  {
    padding: 0rem 2rem;
    border-bottom: 1px solid rgba(231, 234, 243, 0.70);
}
.label_name button {
    font-size: 16px;
    font-family: var(--primary-font-family);
    text-transform: none !important;
}
.label_name_swipe{
    padding: 2rem 2rem;
}